import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { marked } from 'marked';
import io from 'socket.io-client'; // Importer socket.io-client

function App() {
  const [formData, setFormData] = useState({
    prenom: '',
    dateNaissance: '',
    heureNaissance: '',
    villeNaissance: '',
    paysNaissance: ''
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('generale');
  const [token, setToken] = useState(localStorage.getItem('jwt') || '');
  const [clicksCount, setClicksCount] = useState(0);
  const [ip, setIp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(0);

  const apiHost = process.env.REACT_APP_API_HOST || 'http://localhost:5000'; // Utilise une valeur par défaut si la variable n'existe pas
  const [socket, setSocket] = useState(null);

  // Fonction pour vérifier si le token est expiré
  const isTokenExpired = (token) => {
    if (!token) return true;

    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      return tokenPayload.exp < currentTime;
    } catch (error) {
      console.error('Erreur lors de la vérification du token :', error);
      return true;
    }
  };

  // Fonction pour récupérer le token
  const fetchToken = useCallback(async () => {
    try {
      const res = await axios.get(`${apiHost}/api/token`);
      const newToken = res.data.token;
      setToken(newToken);
      localStorage.setItem('jwt', newToken);
    } catch (error) {
      console.error('Erreur lors de la récupération du token', error);
    }
  }, [apiHost]);

  // Calcul du temps restant avant la réinitialisation
  const calculateTimeRemaining = (lastClickTime) => {
    const currentTime = Date.now();
    const timeSinceLastClick = currentTime - lastClickTime;
    const timeLeft = 24 * 60 * 60 * 1000 - timeSinceLastClick; // 24 heures en millisecondes
    return timeLeft > 0 ? timeLeft : 0;
  };

  // Fonction pour incrémenter le compteur de clics
  const incrementClicksCount = () => {
    const currentTime = Date.now();
    const storedData = JSON.parse(localStorage.getItem(ip)) || { clicks: 0, lastClick: 0 };

    const timeSinceLastClick = currentTime - storedData.lastClick;

    if (timeSinceLastClick >= 24 * 60 * 60 * 1000) {
      storedData.clicks = 1; // Réinitialiser après 24h
    } else {
      storedData.clicks += 1;
    }

    storedData.lastClick = currentTime;
    localStorage.setItem(ip, JSON.stringify(storedData));
    setClicksCount(storedData.clicks);
  };

  // Connexion au WebSocket lors du premier rendu
  useEffect(() => {
    const initializeSocket = async () => {
      const serverOnline = await checkServerStatus();
      if (!serverOnline) return;

      const newSocket = io(apiHost, {
        reconnectionAttempts: 5,
        reconnectionDelay: 2000,
        reconnectionDelayMax: 5000,
        timeout: 10000,
      });
      setSocket(newSocket);

      newSocket.on('connect_error', (err) => {
        console.error('Erreur de connexion au serveur WebSocket :', err);
      });

      newSocket.on('disconnect', (reason) => {
        console.log('Déconnecté du serveur WebSocket :', reason);
        if (reason === 'io server disconnect') {
          newSocket.connect();
        }
      });

      // Réception de l'événement 'generationComplete'
      newSocket.on('generationComplete', (data) => {
        setResponse(data.message);
        incrementClicksCount();
        setLoading(false);
      });

      // Cleanup lors de la déconnexion
      return () => newSocket.disconnect();
    };

    initializeSocket();
  }, [apiHost]);

  // Vérification de la santé du serveur
  const checkServerStatus = async () => {
    try {
      const res = await axios.get(`${apiHost}/health-check`); // Route de santé du serveur
      return res.status === 200;
    } catch (error) {
      console.error('Le serveur est hors ligne', error);
      return false;
    }
  };

  // Appel backend API pour générer le thème via WebSocket
  const callBackendAPI = async (data) => {
    try {
      setLoading(true);
      setResponse('');

      const config = {
        headers: {
          'x-api-key': process.env.REACT_APP_CLIENT_API_KEY,
          'Authorization': `Bearer ${token}`,
          'x-socket-id': socket?.id,
        },
      };

      await axios.post(`${apiHost}/api/generate`, data, config); // Envoi du formulaire au backend
    } catch (error) {
      console.error('Erreur lors de l’appel au backend', error);
      setResponse('Erreur lors de la génération de votre thème astral. Veuillez réessayer.');
      setLoading(false);
    }
  };

  // useEffect pour gérer le compteur de clics et le temps restant
  useEffect(() => {
    if (clicksCount >= 2) {
      const storedData = JSON.parse(localStorage.getItem(ip)) || { clicks: 0, lastClick: 0 };
      const timeLeft = calculateTimeRemaining(storedData.lastClick);
      setTimeRemaining(timeLeft);

      const interval = setInterval(() => {
        const updatedTimeLeft = calculateTimeRemaining(storedData.lastClick);
        setTimeRemaining(updatedTimeLeft);

        if (updatedTimeLeft <= 0) {
          clearInterval(interval);
          setErrorMessage('');
          setClicksCount(0); // Réinitialiser après 24h
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [clicksCount, ip]);

  // Affichage dynamique du message d'erreur
  useEffect(() => {
    if (clicksCount >= 2 && timeRemaining > 0) {
      const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      setErrorMessage(`Le nombre maximum de consultations est atteint pour aujourd'hui. Veuillez réessayer dans ${hours} heures et ${minutes} minutes.`);
    }
  }, [timeRemaining]);

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    if (clicksCount >= 2) return;

    const payload = {
      themeName: selectedTheme,
      formData: formData
    };

    callBackendAPI(payload);
  };

  // useEffect pour vérifier le token et récupérer l'adresse IP
  useEffect(() => {
    const initializeApp = async () => {
      const serverOnline = await checkServerStatus();
      if (serverOnline) {
        if (!token || isTokenExpired(token)) fetchToken();

        if (!ip) {
          const fetchIp = async () => {
            try {
              const res = await axios.get(`${apiHost}/api/get-ip`);
              setIp(res.data.ip);
            } catch (error) {
              console.error('Erreur lors de la récupération de l\'adresse IP', error);
            }
          };

          fetchIp();

          // Charger les clics depuis le localStorage avec l'IP
          const storedData = JSON.parse(localStorage.getItem(ip)) || { clicks: 0, lastClick: 0 };
          setClicksCount(storedData.clicks);
        }
      }
    };

    initializeApp();
  }, [token, ip, fetchToken, apiHost]);

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateForm();
  };

  // Validation du formulaire
  const validateForm = () => {
    const { prenom, dateNaissance, heureNaissance, villeNaissance, paysNaissance } = formData;
    if (prenom && dateNaissance && heureNaissance && villeNaissance && paysNaissance) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  // Rendu du formulaire et des résultats
  return (



  <div className="main-container">

  <header>

      <div className='page-header min-vh-75 relative'>
      <div className="container">
      <div className="row addMargin">
      <div className="col-12 ">
<h1 className="text-center text-white pt-3 mt-n5"><span className="highlight">Votre Théme Astral Personnalisé</span></h1>
<p className="text-center lead text-white mt-3">Simple et rapide, vous en apprendrez plus sur votre caractère. </p>
</div>
</div>
          </div>
        <span className="mask bg-gradient-primary"></span>

        <div className="wave-container ">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
            </defs>
            <g className="moving-waves">
              <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)"></use>
              <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
              <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
              <use href="#gentle-wave" x="48" y="7" fill="rgba(255,255,255,0.2)"></use>
              <use href="#gentle-wave" x="48" y="9" fill="rgba(255,255,255,0.1)"></use>
              <use href="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
            </g>
          </svg>
        </div>
      </div> 
    </header>

    <section id="main" className="my-5 py-5">
      <div className="container">

        <div className="row">
          <div className="col-7 mx-auto blur z-index-3 shadow-blur border-radius-xl pdz-mt-n10 pdz-mx-auto pdz-py-3">
            <div className="form-section">
            <div className="container ">
         
            <div className="form-group noShow" >
              <label>Sélectionnez un domaine :</label>
              <select
                className="form-control"
                value={selectedTheme}
                onChange={(e) => setSelectedTheme(e.target.value)}
              >
                <option value="generale">Généralités</option>
                <option value="maison_astro">Maisons astrologiques</option>
                <option value="transits">Transits majeurs à venir et impacts</option>
              </select>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label><span className="required-star">*</span> Prénom</label>
                  <input type="text" className="form-control" name="prenom" onChange={handleChange} required />
                </div>
                <div className="form-group">
                  <label><span className="required-star">*</span> Date de Naissance</label>
                  <input type="date" className="form-control" name="dateNaissance" onChange={handleChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label><span className="required-star">*</span> Heure de Naissance</label>
                  <input type="time" className="form-control" name="heureNaissance" onChange={handleChange} required />
                </div>
                <div className="form-group">
                  <label><span className="required-star">*</span> Ville de Naissance</label>
                  <input type="text" className="form-control" name="villeNaissance" onChange={handleChange} onFocus={handleChange} onBlur={handleChange}  onKeyUp={handleChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group ">
                  <label><span className="required-star">*</span> Pays de Naissance</label>
                  <input type="text" className="form-control" name="paysNaissance" onChange={handleChange} onFocus={handleChange} onBlur={handleChange}  onKeyUp={handleChange} required />
                </div>
              </div>
              <div className="required-info text-end mb-3"><small><span className="required-star">*</span> Champs obligatoires</small></div>

              {/* Affichage du message d'erreur si le nombre de clics est atteint */}
              {errorMessage && (
                <div className="alert alert-danger text-center" role="alert">
                  {errorMessage}
                </div>
              )}
              {/* Bouton désactivé pendant le chargement */}
              <button type="submit" className="btn btn-primary w-100" disabled={!isFormValid || loading}>
                {loading ? 'Génération en cours...' : 'Générer Mon Thème Astral'}
              </button>
            </form>
            </div>
          </div>
          <hr className="horizontal dark mb-5"></hr>
        <div className="mt-4">
          {/* Affichage du loader ou du texte */}
          {loading ? (
            <div className="loader"></div> // Loader à afficher pendant le chargement
          ) : (
            <div
              className="form-control result-container no-copy"
              style={{ height: 'auto', padding: '15px', border: '0px solid #ddd', borderRadius: '6px' }}
              dangerouslySetInnerHTML={{ __html: marked(response) }} // Utilisation de `marked` pour le formatage
            />
          )}
        </div>
          </div>
        </div>
      </div>
    </section>






    <footer className="footer pt-5 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <p className="my-4 text-sm">
              Tous droits réservés. Copyright © <script>
                              document.write(new Date().getFullYear())
                            </script>2024  by <a href="https://www.pandaaz.com" target="_blank" rel="noreferrer noopener">Pandaaz</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    
   

</div>
  );
}

export default App;